
import Vue from "vue";

export default Vue.extend({
  name: "RecycleCard",
  props: {
    sourceMaterialGroup: Object,
  },
  data: () => {
    return {
      materialGroup: {} as any,
    };
  },
  created() {
    this.materialGroup = this.sourceMaterialGroup;
  },
  computed: {
    errorState(): any {
      return {
        totalError: (this.materialGroup.totalPercentage > 100 || this.materialGroup.totalPercentage < 100) && this.materialGroup.totalPercentage !== 0,
        recycled: this.materialGroup.percentage.recycled > 100,
        incinerated: this.materialGroup.percentage.incinerated > 100,
        fuelReplacement: this.materialGroup.percentage.fuelReplacement > 100,
        disposed: this.materialGroup.percentage.disposed > 100,
        disposedByIncineration: this.materialGroup.percentage.disposedByIncineration > 100,
      };
    },
  },
  methods: {
    checkUserInput($userInput: any) {
      const keyCode = $userInput.keyCode ? $userInput.keyCode : $userInput.key;
      // allow only numbers
      if (keyCode < 48 || keyCode > 57) {
        $userInput.preventDefault();
      }
    },
    getErrorState(type: string) {
      if (this.errorState) {
        return this.errorState[type];
      }
      return false;
    },
    calculateSum() {
      this.materialGroup.totalPercentage =
        Number(this.materialGroup.percentage.recycled) +
        Number(this.materialGroup.percentage.incinerated) +
        Number(this.materialGroup.percentage.fuelReplacement) +
        Number(this.materialGroup.percentage.disposed) +
        Number(this.materialGroup.percentage.disposedByIncineration);
      this.materialGroup.emptyCard = this.materialGroup.totalPercentage === 0;
      this.materialGroup.cardError = !this.materialGroup.emptyCard && this.materialGroup.totalPercentage !== 100;
      this.$emit("update-material-group", this.materialGroup);
    },
    checkValue(type: string) {
      if (
        this.materialGroup.percentage[type] < 0 ||
        this.materialGroup.percentage[type] === null ||
        this.materialGroup.percentage[type] === ''
      ) {
        this.materialGroup.percentage[type] = 0;
      }
      this.updatePie(type);
      this.calculateSum();
    },
    updatePie(type: string) {
      this.materialGroup.animatedPercentage[type] = this.materialGroup.percentage[type];
    },
  }
});
