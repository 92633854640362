import { render, staticRenderFns } from "./RecycleReport.vue?vue&type=template&id=3675b190"
import script from "./RecycleReport.vue?vue&type=script&lang=ts"
export * from "./RecycleReport.vue?vue&type=script&lang=ts"
import style0 from "./RecycleReport.vue?vue&type=style&index=0&id=3675b190&prod&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports