
import ContactForm from "@/components/ContactForm.vue";
import RecycleReport from "@/components/RecycleReport.vue";
import SubDrawer from "@/components/SubDrawer.vue";
import LoadingComponent from "@/components/atoms/LoadingComponent.vue";
import {
  MaterialVolumeInGroup,
  RecyclingQuotePayload,
  ReporterInfo,
} from "@/interfaces";
import Vue from "vue";
import { mapState } from "vuex";

export default Vue.extend({
  name: "Dashboard",
  data: () => {
    return {
      componentKey: 0,
      inProgress: false,
      showForm: false,
      payload: {
        disposerNumber: 0,
        businessYear: 0,
        reportId: "",
        submitDate: new Date().toLocaleDateString("de-DE", {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
        }),
        materialVolumes: [] as MaterialVolumeInGroup[],
        reporter: {} as ReporterInfo,
      } as RecyclingQuotePayload,
    };
  },
  components: {
    RecycleReport,
    ContactForm,
    SubDrawer,
    LoadingComponent,
  },
  computed: {
    ...mapState({
      disposer: (state: any) => state.disposerManagement.disposer,
      businessYear: (state: any) =>
        state.disposerManagement.notification.businessYear,
      reportId: (state: any) => state.disposerManagement.notification.reportId,
    }),
  },
  created() {
    const iOs =
      navigator.userAgent.match(/iPad/i) ||
      navigator.userAgent.match(/iPhone/i);

    const eventName = iOs ? "pagehide" : "beforeunload";

    window.addEventListener(eventName, function (e) {
      var confirmationMessage = "";
      if (!iOs) {
        //Desktop only (Chrome, Firefox, Safari)
        (e || window.event).returnValue = confirmationMessage;
      }
      return confirmationMessage;
    });
  },
  mounted() {
    this.payload.disposerNumber = this.disposer.disposerNumber;
    this.payload.businessYear = this.businessYear;
    this.payload.reportId = this.reportId;
  },

  methods: {
    updateQuotePayload(inputPayload: any) {
      const materialVolumes = [] as MaterialVolumeInGroup[];
      inputPayload.forEach((material: any) => {
        materialVolumes.push({
          groupName: material.groupName,
          percentage: material.percentage,
        });
      });
      this.payload.materialVolumes = materialVolumes;
      this.showForm = true;
    },
    resetPayload() {
      this.payload.materialVolumes = [];
      this.payload.reporter = {} as ReporterInfo;
      this.showForm = false;
      this.componentKey++;
    },
    setReporter(reporter: any) {
      this.payload.reporter = reporter;
      this.submitRecyclingQuote();
    },
    submitRecyclingQuote() {
      this.inProgress = true;
      this.$store
        .dispatch("submitQuote", this.payload)
        .then(() => {
          this.inProgress = false;
          this.$router.push({
            name: "Success",
            params: { reportId: this.payload.reportId },
            query: { done: "true" },
          });
        })
        .catch((e) => {
          this.inProgress = false;
        });
    },
    updateReporter(reporter: ReporterInfo) {
      this.payload.reporter = reporter;
    },
  },
});
