
import RecycleCard from "@/components/atoms/RecycleCard.vue";
import RecycleConfirm from "@/components/atoms/RecycleConfirm.vue";
import Vue from "vue";
import { mapState } from "vuex";

export default Vue.extend({
  name: "RecycleReport",
  components: {
    RecycleCard,
    RecycleConfirm,
  },
  data: () => {
    return {
      reportInputs: [] as any,
      lockEntry: false,
      carouselModel: 0,
      selectedBuble: 0,
    };
  },
  watch: {
    lockEntry: function (val) {
      this.reportInputs.forEach((item: any) => {
        item.cardKey = Math.random();
      });
    },
  },
  computed: {
    ...mapState({
      report: (state: any) => state.disposerManagement.report,
      reportId: (state: any) => state.disposerManagement.notification.reportId,
    }),
    isMobile() {
      return this.$vuetify.breakpoint.smAndDown;
    },
  },
  mounted() {
    this.reportInputs = JSON.parse(JSON.stringify(this.report.materialVolumes));
    for (const report of this.reportInputs) {
      report.animatedPercentage = {
        recycled: report.percentage.recycled,
        incinerated: report.percentage.incinerated,
        fuelReplacement: report.percentage.fuelReplacement,
        disposed: report.percentage.disposed,
        disposedByIncineration: report.percentage.disposedByIncineration,
      };
      report.cardKey = Math.random();
      report.emptyCard = report.totalPercentage === 0;
      report.cardError = !report.emptyCard && report.totalPercentage !== 100;
    }
    this.reportInputs.sort((a: any, b: any) =>
      a.groupName < b.groupName ? -1 : 1
    );
  },
  methods: {
    clickLock() {
      if (!this.lockEntry) {
        const cardError = this.reportInputs.some((item: any) => item.cardError);
        const emptyCard = this.reportInputs.some((item: any) => item.emptyCard);
        if (cardError || emptyCard) {
          if (cardError) {
            this.$toast.error("Bitte prüfen Sie Ihre Eingaben.", {
              y: "bottom",
              timeout: 1500,
            });
          }
          if (emptyCard) {
            this.reportInputs.forEach((item: any) => {
              item.totalError = item.emptyCard;
            });
            this.$toast.error(
              "Bitte füllen Sie alle Karten aus. Klicken Sie auf die Pfeile um zur nächsten Karte zu gelangen.",
              {
                y: "bottom",
                timeout: 1500,
              }
            );
          }
          if (this.isMobile) {
            this.carouselModel = this.reportInputs.findIndex(
              (item: any) => item.cardError || item.emptyCard
            );
          } else {
            this.$vuetify.goTo("#cards");
          }
          return;
        } else {
          this.lockEntry = true;
          this.$emit("entry-confirmed", this.reportInputs);
        }
      } else {
        this.lockEntry = false;
        this.$emit("entry-canceled");
      }
      if (this.isMobile) {
        this.carouselModel = 0;
      }
    },
    updateMaterialGroup(materialGroup: any) {
      this.reportInputs.forEach((item: any) => {
        if (item.groupName === materialGroup.groupName) {
          item.percentage = materialGroup.percentage;
          item.totalPercentage = materialGroup.totalPercentage;
          item.cardError = !item.emptyCard && item.totalPercentage !== 100;
        }
      });
    },
  },
});
